import React from 'react'

import { Education, NavBarBlock, Experience, ScrollToTop } from '../../components'
import styles from './Curriculum.module.css'

const Curriculum = () => {
    
  return (
    <>  
        <NavBarBlock />
        {/* <div className={styles.header}/> */}
        <div className={styles.contentWrapper}>
          <Experience title={'experience'}/>
          <Education title={'education'}/>
        </div>
        <ScrollToTop />
        {/* <div className={styles.footer}/> */}
    </>
  )
}

export default Curriculum