import React from 'react'

import { Menu, Logo} from '../../components'
import styles from './HomePage.module.css'

const HomePage = () => {
  return (
    <>
        <Menu />
        <div className={styles.content}>
            <Logo />
        </div> 
    </>
  )
}

export default HomePage