import React from 'react'

import styles from './Logo.module.css'

const Logo = () => {
  return (
        <div className={styles.wrapper}>
            {/* <h1 className={styles.name}>Rafał Karczmarczyk</h1> */}
            {/* <h2 className={styles.role}>CONDUCTOR</h2> */}
            <img src={require("../../assets/logo_v3.png")} alt="RK" width="100" height="90" className={styles.logo} />
        </div>
  )
}

export default Logo