import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Concerts, Contact, Curriculum, HomePage } from './components'

import './App.module.css'

const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />}/>
        <Route path='/cv' element={<Curriculum />}/>
        <Route path='/concerts' element={<Concerts />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </>
  )
}

export default App