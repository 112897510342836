import React, {useState} from 'react'

import { NavBarBlock, ScrollToTop } from '../index'
import styles from './Concerts.module.css'
import { concerts } from '../../constants/concerts.js'
import openDotsImage from '../../assets/open.png'
import closeDotsImage from '../../assets/close.png'

const Concerts = () => {
  const [openItems, setOpenItems] = useState({});

  const toggleText = (id) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [id]: !prevOpenItems[id],
    }));
  };

  return (
    <>
      <NavBarBlock />
      {/* <div className={styles.header}/> */}
        <div className={styles.margin}>
          {concerts.map((concerts, index) => (
            <div className={styles.content}>
              <div 
                // className={concerts.assistant === 'yes' ? styles.assistantTitleWrapper : styles.conductorTitleWrapper}
                className={styles.conductorTitleWrapper}
              >
                <div className={styles.title}>{concerts.title}</div>
                <div className={styles.subtitle}>{concerts.subtitle}</div>
                
                {/* TODO - change to map logic */}
                <div className={concerts.piece1
                      ? styles.visible
                      : styles.hidden}>
                  <div className={styles.works}>
                    <ul>{concerts.piece1}</ul>
                    <ul>{concerts.piece2}</ul>
                    <ul>{concerts.piece3}</ul>
                    <ul>{concerts.piece4}</ul>
                    <ul>{concerts.piece5}</ul>
                    <ul>{concerts.piece6}</ul>
                    <ul>{concerts.piece7}</ul>
                  </div>
                </div>

                <div className={styles.premiere}>{(concerts.premiere === 'PREMIERE') && concerts.premiere}</div>
              </div>
              <div className={styles.details}>
                <div>
                  <div className={styles.date}>{concerts.date}</div>
                  <div className={styles.place}>{concerts.place}</div>
                </div>

                <div className={concerts?.cast 
                      ? styles.visible
                      : styles.hidden }
                >
                  <div key={concerts.id}>
                    <img 
                      src={openItems[concerts.id] ? openDotsImage : closeDotsImage}
                      alt='Toggle'
                      className={styles.toggleImage}
                      onClick={() => toggleText(concerts.id)}/>
                    {openItems[concerts.id] && (
                      <div className={styles.hiddenText}>
                        <div className={styles.castWrapper}>                          
                          {concerts.cast.map((role, index) => (
                            <div key={index}>
                              {role}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>                
                
                <div className={styles.castWrapper}>
                  {/* <div>{concerts.cast}</div>
                  <div>{concerts.cast1}</div> */}
                  <div>{concerts.orchestra}</div>
                  <div className={styles.producerWrapper}>
                    <div className={concerts.director 
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.director} - Director
                    </div>
                    <div className={concerts.music 
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.music} - Music
                    </div>
                    <div className={concerts.choreography 
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.choreography} - Choreography
                    </div>
                    <div className={concerts?.conductor 
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.conductor} - Conductor
                    </div>
                    <div className={concerts?.scenography 
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.scenography} - Scenography
                    </div>
                    <div className={concerts?.balletDirector 
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.balletDirector} - Ballet Director
                    </div>
                    <div className={concerts.musicalCooperation
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.musicalCooperation} - Musical cooperation
                    </div>
                    <div className={concerts.conductorAssistant
                      ? styles.visible
                      : styles.hidden }
                    >
                      {concerts.conductorAssistant} - Conductor Assistant
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
      <ScrollToTop />
      {/* <div className={styles.footer}/> */}
    </>
  )
}

export default Concerts