import React from 'react'

import styles from './Menu.module.css'

const Menu = () => {
  return (
        <div className={styles.wrapper}>
          <p><a href='/'>home</a></p>
          <p><a href='/#/cv'>cv</a></p>
          <p><a href='/#/concerts'>work</a></p>
          <p><a href='/#/contact'>contact</a></p>
        </div>
      )
}

export default Menu