import React from "react";
import styles from '../Curriculum/Curriculum.module.css'

const Education = ({ title }) => {

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      
      <div id='slider' className={styles.row_posters}>
        <div className={styles.frame}>
          <div className={styles.content}>
            <div className={styles.date}>2019</div>
            <div className={styles.institution}>Conservatorio di Musica <br/> Santa Cecilia di Roma</div>
            <div className={styles.role}>Postgraduate Studies • Conducting</div>
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.content}>
            <div className={styles.date}>2017 - 2018</div>
            <div className={styles.institution}>The Chopin University <br/>of Music in Warsaw</div>
            <div className={styles.role}>Postgraduate Degree • Management in Music</div>
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.content}>
            <div className={styles.date}>2016 - 2018</div>
            <div className={styles.institution}>Academy of Music <br/> in Cracow</div>
            <div className={styles.role}>Master's degree • Conducting</div>
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.content}>
            <div className={styles.date}>2016 - 2017</div>
            <div className={styles.institution}>Akademie múzických umení <br/> v Praze</div>
            <div className={styles.role}>Conducting</div>
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.content}>
            <div className={styles.date}>2013 - 2016</div>
            <div className={styles.institution}>Academy of Music <br/> in Cracow</div>
            <div className={styles.role}>Bachelor's degree • Conducting</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education