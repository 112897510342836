import React from 'react'

import { Menu, Logo } from '../../index'
import styles from './NavbarBlock.module.css'

const NavBarBlock = () => {
  return (
    <div className={styles.content}>
        <div className={styles.logoWrapper}>
            <Logo />
        </div> 
        <div className={styles.navbarWrapper}>
            <Menu />
        </div>
    </div>
  )
}

export default NavBarBlock